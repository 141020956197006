import { Box, Button, Flex, Icon, Tooltip } from '@energiebespaarders/symbols';
import { Center, Color, Medium, Small } from '@energiebespaarders/symbols/helpers';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import {
  CaretRight,
  CheckCircle,
  Info,
  Information,
} from '@energiebespaarders/symbols/icons/solid';
import { rgba } from 'polished';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { shade, themify } from '../../styles/mixins';
import theme from '../../styles/theme';
import { notEmpty } from '../../typeHelpers';
import { DutyName } from '../../types/graphql-global-types';
import delimit from '../../utils/delimit';
import {
  CustomDutyPackageProps,
  DutyPackageWithPrice,
  NonDutyFeature,
  PackageCardItem,
  getPackageFeature,
} from '../../utils/dutyPackages';
import HybridLink from '../HybridLink';

const StyledPackageFeature = styled.div`
  line-height: 2;
  font-size: ${x => x.theme.type.scale[6]};
`;

const StyledPackageButton = styled(Button)`
  border-radius: 100px;
`;

const StyledLink = styled(HybridLink)<{ color: string }>`
  color: ${x => themify(x.color)};

  &:hover {
    color: ${x => shade(0.9, themify(x.color))};
  }
`;

interface PackageFeaturesProps {
  setActiveDutyPackage?: () => void;
  dutyPackage: DutyPackageWithPrice;
  hideButton?: boolean;
  customPackageProps?: CustomDutyPackageProps;
  useGenericDescriptions?: boolean;
  customFeatures?: string[];
  customColor?: string;
}

const PackageFeatures: React.FC<PackageFeaturesProps> = ({
  setActiveDutyPackage,
  dutyPackage,
  hideButton,
  customPackageProps,
  useGenericDescriptions = false,
  customFeatures,
  customColor,
}) => {
  const isMobile = useIsMobile();

  const getColor = useCallback(
    (feature: PackageCardItem) => {
      if (customColor || customPackageProps?.color) {
        return themify(customColor || customPackageProps?.color);
      }
      if (
        dutyPackage.additions?.includes(feature.key) ||
        dutyPackage.additions?.length === 0 ||
        !dutyPackage.additions
      ) {
        return themify(dutyPackage.color);
      }
      return themify(dutyPackage.additionsColor || dutyPackage.color);
    },
    [
      customColor,
      customPackageProps?.color,
      dutyPackage.additions,
      dutyPackage.additionsColor,
      dutyPackage.color,
    ],
  );
  const hasDecimals = useMemo(() => {
    return dutyPackage.price! % 1 !== 0;
  }, [dutyPackage.price]);
  const packagesFeatures = dutyPackage.features?.map(key =>
    getPackageFeature(key as DutyName | NonDutyFeature),
  );

  const customPackageFeatures = customFeatures?.map(key =>
    getPackageFeature(key as DutyName | NonDutyFeature),
  );
  return (
    <>
      {(customPackageFeatures || packagesFeatures)?.filter(notEmpty).map((feature, index) => (
        <StyledPackageFeature key={`feature-${index}`}>
          <Flex flexWrap="wrap">
            <Box width="3em">
              <Icon
                icon={CheckCircle}
                solid
                fill={getColor(feature)}
                mr={isMobile ? 2 : 4}
                width={theme.type.scale[4]}
              />
            </Box>

            <Box width="calc(100% - 3em)">
              {!customFeatures && dutyPackage.additions?.includes(feature.key) ? (
                <Color c={themify(dutyPackage.color)}>
                  <Medium>{feature.label}</Medium>
                </Color>
              ) : (
                feature.label
              )}
              {!!feature.description && (
                <Tooltip
                  bgColor="grayDark"
                  content={
                    (useGenericDescriptions && feature.genericDescription) || feature.description
                  }
                  padding={2}
                  lineHeight={1.5}
                  trigger={isMobile ? 'click focus' : 'click hover focus touch'}
                >
                  <Icon
                    icon={Information}
                    solid
                    fill={rgba(getColor(feature), 0.3)}
                    hoverColor={getColor(feature)}
                    mx={1}
                    width={theme.type.scale[7]}
                  />
                </Tooltip>
              )}
            </Box>
          </Flex>
        </StyledPackageFeature>
      ))}

      {!hideButton && (
        <StyledPackageButton
          bgColor={themify(dutyPackage.color)}
          fluid
          inverse
          fontSize={5}
          iconEnd={CaretRight}
          label={`${dutyPackage.callToAction} voor €${delimit(
            dutyPackage.price!,
            hasDecimals ? 2 : 0,
          )}`}
          mt={3}
          onClick={setActiveDutyPackage}
        />
      )}

      {dutyPackage.features?.includes(DutyName.virtualVisit) && (
        <Center block>
          <Small>
            <Icon icon={Info} solid fill="blue" mr={1} />
            <StyledLink
              color={dutyPackage.color}
              to="/blog/hoe-verloopt-een-woningopname-van-de-energiebespaarders-in-tijden-van-het-coronavirus"
              className="image-link"
            >
              Hoe verloopt een digitaal huisbezoek?
            </StyledLink>
          </Small>
        </Center>
      )}
    </>
  );
};

export default PackageFeatures;

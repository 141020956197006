import { Box, Button, Card, Flex, Image } from '@energiebespaarders/symbols';
import { Color, Heading, Smaller } from '@energiebespaarders/symbols/helpers';
import { CaretRight } from '@energiebespaarders/symbols/icons/solid';
import mediaMin from '@energiebespaarders/symbols/styles/breakpoints';
import { rgba } from 'polished';
import React, { useCallback, useEffect, useMemo } from 'react';
import { scrollSpy } from 'react-scroll';
import styled, { css } from 'styled-components';
import useMediaQuery from '~/hooks/useMediaQuery';
import { getPartnerLogo } from 'public/img/partners';
import { isUndefined } from '../../envPartner/PartnerCheck/utils';
import { padding, themify } from '../../styles/mixins';
import { DutyPackageName } from '../../types/graphql-global-types';
import delimit from '../../utils/delimit';
import { CustomDutyPackageProps, DutyPackageWithPrice } from '../../utils/dutyPackages';
import PackageFeatures from './PackageFeatures';
import PackageRibbon from './PackageRibbon';

interface StyledPackageCardProps {
  $bgColor: string;
  $isGreenFriday?: boolean;
}

export const StyledPackageCard = styled(Card)<StyledPackageCardProps>`
  position: relative;
  background-color: white;
  background-image: ${x =>
    `linear-gradient(to bottom, white 80%, ${rgba(themify(x.$bgColor || 'green'), 0.15)})`};
  border-top: 3px solid ${x => themify(x.$bgColor)};
  transition: transform 0.25s ${x => x.theme.curves.standard};
  font-size: ${x => x.theme.type.scale[6]};

  &:hover {
    transform: translateY(-3px);
  }

  ${mediaMin.lg} {
    font-size: ${x => x.theme.type.scale[5]};
  }

  ${x =>
    x.$isGreenFriday &&
    css<typeof x>`
      background-color: black;
      background-image: ${x =>
        `linear-gradient(to bottom, black 40%, ${rgba(themify(x.$bgColor), 0.35)})`};
      color: white;
    `}
`;

const StyledPartnerLogo = styled(Image)`
  max-height: 45px;
  max-width: 120px;
`;

export const StyledPackageTitle = styled(Heading)`
  line-height: 1;
`;

export const StyledDescription = styled.div`
  font-size: ${x => x.theme.type.scale[6]};
  margin-bottom: 1em;

  ${mediaMin.md} {
    min-height: 4em;
  }
`;

export const StyledPrice = styled(Box)`
  font-size: 3em;
  letter-spacing: -3px;
  line-height: 1;
`;

export const StyledEuro = styled.div`
  position: relative;
  top: -0.75em;
  left: 0.1em;
  display: inline-block;
  font-size: 0.5em;
`;

export const StyledOriginalPrice = styled.div`
  display: inline-block;
  ${padding(2, 'x')};
  transform: scale(0.75);
`;

export const StyledPackageButton = styled(Button)`
  border-radius: 100px;
`;

interface PackageCardProps {
  dutyPackage: DutyPackageWithPrice;
  handleActiveDutyPackage: (dp: DutyPackageName | string) => void;
  useGenericDescriptions?: boolean;
  hideCta?: boolean;
  customPackageProps?: CustomDutyPackageProps;
}

const PackageCard: React.FC<PackageCardProps> = ({
  dutyPackage,
  handleActiveDutyPackage,
  useGenericDescriptions,
  hideCta,
  customPackageProps,
}) => {
  const isTablet = useMediaQuery('lg');
  const isDesktop = !isTablet;

  const packageProps = useMemo(
    () => ({ ...dutyPackage, ...(customPackageProps || {}) }),
    [customPackageProps, dutyPackage],
  );

  useEffect(() => {
    scrollSpy.update();
  }, []);

  const StyledTitle = useCallback(() => {
    const titleWords = packageProps.fullName.split(' ');
    if (['Direct', 'Simpel', 'Compleet'].some(w => w === titleWords[1])) {
      return (
        <StyledPackageTitle heading="h3" letterSpacing="-0.025em">
          {titleWords[0]} <Color c={packageProps.color}>{titleWords[1]}</Color>{' '}
          {titleWords.splice(2).join(' ')}
        </StyledPackageTitle>
      );
    } else {
      return (
        <StyledPackageTitle heading="h3" letterSpacing="-0.025em">
          {packageProps.fullName}
        </StyledPackageTitle>
      );
    }
  }, [packageProps]);

  const hasDiscount =
    !isUndefined(packageProps.nonDiscountedPrice) &&
    packageProps.nonDiscountedPrice !== packageProps.price;

  const hasDecimals = packageProps.price! % 1 !== 0;

  return (
    <StyledPackageCard $bgColor={packageProps.color} mb={3} px={0}>
      {packageProps.ribbon ? (
        <PackageRibbon
          bgColor={packageProps.ribbonColor || packageProps.color}
          text={packageProps.ribbon}
        />
      ) : hasDiscount && packageProps.ribbon !== '' ? (
        <PackageRibbon
          bgColor={packageProps.color}
          text={`${
            100 - Math.round((packageProps.price! / packageProps.nonDiscountedPrice!) * 100)
          }% korting`}
        />
      ) : (
        <></>
      )}
      <Flex flexWrap="wrap" pt={2}>
        <Box width={1} px={[1, 2, 2, 3]} textAlign="center">
          <StyledTitle />
          <StyledDescription>{packageProps.customerDescription}</StyledDescription>
        </Box>

        <Box width={1} px={[1, 2, 2, 3]} textAlign="center" mb={1}>
          {hasDiscount ? (
            <StyledPrice>
              <StyledOriginalPrice className="gray strikethrough">
                {packageProps.nonDiscountedPrice}
                <StyledEuro>€</StyledEuro>
              </StyledOriginalPrice>
              {delimit(packageProps.price!, hasDecimals ? 2 : 0)}
              <StyledEuro>€</StyledEuro>
            </StyledPrice>
          ) : (
            <StyledPrice>
              {packageProps.price}
              <StyledEuro>€</StyledEuro>
            </StyledPrice>
          )}
        </Box>

        <Box width={1} px={[1, 2, 2, 3]} mt={2} mb={3}>
          <StyledPackageButton
            bgColor={packageProps.color}
            fluid
            fontSize={isDesktop ? 5 : 6}
            iconEnd={CaretRight}
            label={packageProps.callToAction}
            mb={isDesktop ? 3 : 0}
            onClick={() => handleActiveDutyPackage(packageProps.key)}
          />

          <PackageFeatures
            setActiveDutyPackage={() => handleActiveDutyPackage(packageProps.key)}
            dutyPackage={dutyPackage}
            hideButton={dutyPackage.key === DutyPackageName.energiepaspoortLegacy || hideCta}
            useGenericDescriptions={useGenericDescriptions}
            customPackageProps={customPackageProps}
            customFeatures={
              customPackageProps?.features?.length ? [...customPackageProps.features] : undefined
            }
          />
        </Box>

        {(customPackageProps?.logo || getPartnerLogo(packageProps.icon)) && (
          <Box width={1} px={[1, 2, 2, 3]} mb={3} textAlign="center">
            <p>
              <Color c="gray">
                <Smaller>Dit pakket is speciaal samengesteld voor</Smaller>
              </Color>
            </p>
            <StyledPartnerLogo
              src={customPackageProps?.logo || getPartnerLogo(packageProps.icon!)}
              alt={packageProps.fullName}
            />
          </Box>
        )}
      </Flex>
    </StyledPackageCard>
  );
};

export default PackageCard;

import { FloorType, RoofType, getGlazingType } from '@energiebespaarders/constants';
import _ from 'lodash';
import { getApartmentPropertiesFromType } from '~/components/Results/utils';
import { QuestionKey } from '~/components/SavingsCheck/getCards';
import { partnerAgentQuestions } from '../../types/generated/partnerAgentQuestions';
import { getValueFromKey } from '../../utils/getValueFromKey';
import { ExtendedQuestionKey } from '../PartnerCheckExtended/extendedCards';

export function toggleArrayCheckbox<T = any>(valueToAdd: T, array: T[] = []): Array<T> {
  if (valueToAdd === null || valueToAdd === undefined) {
    return array || [];
  }
  const newArray = [...array];
  if (newArray.includes(valueToAdd)) {
    return newArray.filter(x => x !== valueToAdd);
  } else {
    newArray.push(valueToAdd);
  }
  return newArray;
}

export function isUndefined(value: any, evalEmptyArrayAsUndefined = false) {
  if (
    evalEmptyArrayAsUndefined &&
    ((Array.isArray(value) && value.length === 0) || _.isEqual(value, []))
  ) {
    return true;
  }
  if (value === null || value === undefined) return true;
  if (value === '') return true;

  if (value === false) return false;
  if (value === 0) return false;
  return false;
}

export const getPrefilledAnswers = (
  data: partnerAgentQuestions,
  isExtendedCheck: boolean,
): Partial<Record<QuestionKey | ExtendedQuestionKey, any>> => {
  // NOTE: Prefill answers from our data if the check's already been done before OR when using the extended check
  const h = data.house;
  const s = h.situation;
  const i = isExtendedCheck ? h.intake : h;

  function intakeOrHouseVal<T>(key: string): T {
    const houseValue: T = getValueFromKey(key, h);
    const intakeValue: T = getValueFromKey(key, i);
    return !isUndefined(intakeValue) ? intakeValue : houseValue;
  }

  const cy = intakeOrHouseVal<number>('constructionYear')!;
  const boilerAge = intakeOrHouseVal<number>('heating.boilerAge')!;
  const residents = intakeOrHouseVal<number>('residents')!;
  const wallsRc = intakeOrHouseVal<number>('walls.rc');
  const floorRc = intakeOrHouseVal<number>('floor.rc');
  const roofRc = intakeOrHouseVal<number>('floor.rc');
  const windows = {
    livingU: h.intake?.windows.livingU || h.windowsZTAU!.livingU!,
    sleepingU: h.intake?.windows.sleepingU || h.windowsZTAU!.sleepingU!,
  };

  const centralHeatingBoilerAge =
    boilerAge === 0 ? 0 : boilerAge > 0 ? Math.ceil(boilerAge / 5) * 5 : -1;
  const boilerInstallationYear =
    s.heating.boilerInstallationYear || !isUndefined(boilerAge)
      ? new Date().getFullYear() - boilerAge
      : '';
  return {
    isVve: intakeOrHouseVal('vve'),
    apartmentProperties: getApartmentPropertiesFromType(i.type || h.type),
    hasWallCavity: intakeOrHouseVal<boolean>('walls.cavity'),
    hasWallInsulation: wallsRc > (cy >= 1975 ? 2.58 : 2.14),
    hasWallInsulationFollowUp: wallsRc === 4,
    hasExtraWallInsulation: wallsRc === 4,
    hasCrawlspace: Boolean(i.crawlspace?.compartments && i.crawlspace.compartments > 0),
    hasFloorInsulation: floorRc > (cy >= 1975 ? 2.57 : 1.93),
    hasFloorInsulationFollowUp: floorRc === 3.5,
    hasExtraFloorInsulation: floorRc === 3.5,
    hasRoofInsulation: roofRc > (cy >= 1975 ? 2.44 : 2),
    hasRoofInsulationFollowUp: roofRc === 4,
    hasExtraRoofInsulation: roofRc === 4,
    livingGlassType: getGlazingType(windows.livingU, false),
    sleepingGlassType: getGlazingType(windows.sleepingU, false),
    panelAmount: s.solar.currentSystem.panels,
    boilerAge: centralHeatingBoilerAge,
    hasWeatherStripping: i.heating.weatherStripping,
    residentAmount: Math.ceil(residents),
    isCurrentOccupant: s.currentOccupant,
    hasBath: i.appliances.bathtub,
    hasDishWasher: i.appliances.dishwasher,
    slurpers: s.currentSlurpers,
    houseConsumption: {
      gas: Math.round(
        s.consumption.gas ?? h.energy.gasConsumption * (s.remainingGasPercentage ?? 1),
      ),
      electricity: Math.round(
        s.consumption.electricity ??
          h.energy.electricityConsumption -
            h.energy.electricityProduction +
            (s.slurperConsumption ?? 0),
      ),
    },
    energyPrices: {
      gas: s.energyPrices.gas,
      electricity: s.energyPrices.electricity,
    },

    // EXTENDED CHECK QUESTIONS
    crawlspaceHatchAccessible: !!s.crawlspace.hatchAccessible,
    crawlspaceHeight: i.crawlspace?.height || h.crawlspace?.height,
    atticIsLivingSpace: !!s.roof.atticIsLivingSpace,
    roofType: intakeOrHouseVal<RoofType>('roof.type'),
    roofState: s.roof.state,
    roofing: i.roof.roofing,
    roofShading: s.solar.roofShading,
    floorTypes: (i.floor.types || h.floor.types)?.includes(FloorType.Wood)
      ? 'wood'
      : (i.floor.types && !i.floor.types.includes(FloorType.Other)) ||
        (h.floor.types && !h.floor.types.includes(FloorType.Other))
      ? 'concrete'
      : undefined,
    windowFramesState: s.windows.state,
    wallsArea: Math.round(i.walls.area || h.walls.area || 0),
    floorArea: Math.round(i.floor.area || h.floor.area || 0),
    roofArea: Math.round(i.roof.area || h.roof.area || 0),
    boilerInstallationYear,
    CW: s.heating.CW,
  };
};
